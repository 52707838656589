<template>
  <div @click="$router.go(-1)" class="p-mb-4" style="width: fit-content; cursor: pointer;">
    <i class="pi pi-arrow-left" style="display: inline-block; padding-right: 4px;"></i><h5 style="display: inline-block;"> Regresar</h5>
  </div>
  <div>
    <Card style="width: auto; margin-bottom: 2em">
      <template #title>
        Actualizar Foodie
      </template>
      <template #content>
        <div style="display: flex; justify-content: center;">
          <div style="flex: 1 1 auto; margin-right: 25px;">
            <div style="display:block; margin: 0 0 0 auto; width: fit-content;">
              <div style="">
                <h4 >Nombre</h4>
                <InputText id="name" type="text" v-model="body.name" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h4>Email</h4>
                <InputText type="text" v-model="body.email" style="width: 20rem;" />
              </div>
              <div class="p-mt-4">
                <h4>Instagram Username</h4>
                <InputText type="text" v-model="body.ig_username" style="width: 20rem;" />
              </div>
            </div>
          </div>

          <div style="flex: 1 1 auto;">
            <div>
              <h4>Teléfono</h4>
              <InputText type="text" v-model="body.phone" style="width: 20rem;"/>
            </div>
            <div class="p-mt-4">
              <h4>Biografía</h4>
              <Textarea v-model="body.biography" rows="auto" style="width: 20rem;"/>
            </div>
          </div>
        </div>
        <Button @click="updateFoodie" style="display: block; margin: 0 auto;" class="p-mt-4">Actualizar</Button>

      </template>
    </Card>
  </div>
  <Toast />
</template>
<script>
export default {
  name: 'update',
  data () {
    return {
      foodie: [],
      body: {
        name: '',
        phone: '',
        biography: ''
      },
      errors: []
    }
  },
  methods: {
    updateFoodie () {

      this.$repository.foodies
        .update(this.$route.params.foodieId, this.body)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: `El foodie ha sido actualizado`,
            life: 1000
          });
        })
        .catch((err) => {
          const summary = err.response.data?.message
          const detail = err.response.data?.description
          this.$toast.add({
            severity: 'error',
            summary,
            detail,
            life: 3000
          });
        })
    }
  },
  mounted () {
    this.$repository.foodies
      .show(this.$route.params.foodieId)
      .then((response) => {
        this.foodie = response.data
        this.body = {
          name: response.data.name,
          biography: response.data.biography,
          email: response.data.email,
          ig_username: response.data.ig_username
        }
        if (response?.data?.phone) {
          this.body.phone = response.data.phone
        }
    })
  }
}
</script>

<style scoped>

</style>
